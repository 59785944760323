import './App.css'
import React from 'react';
import { BrowserRouter as Router, Routes as Switch, Route,Outlet } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import HomePage from './components/HomePage/HomePage';
import RegisterPage from './components/RegisterPage/RegisterPage'
import LoginPage from './components/LoginPage/LoginPage'
import EmailVericationPage from './components/EmailVericationPage/EmailVericationPage';
import UploadPage from './components/FileUploadPage/FileUploadPage'






function App(){      

    return (
      
      <Router>

        <Switch>

          <Route path="/"  element={
                    
            <div>
              <div className='navbarContainerReceiver'>
                <Navbar/>         
              </div>
              <div className='bodyContainerReceiver'>
                <Outlet/>
              </div>
              <div className='footerContainerReceiver'>
                <Footer/>         
              </div>
            </div>             
                            

          }>

            <Route  path="/"  element={<HomePage/>}/>

            
            
          </Route>

                    
          <Route exact path="/register" element={<RegisterPage/>}/>
          <Route exact path="/login" element={<LoginPage/>}/>
          <Route exact path="/upload" element={<UploadPage/>}/>
          <Route  exact path="/verify_email/:email_address"  element={<EmailVericationPage/>}/>




        

        </Switch>

      </Router>

    )  
}
  






   


export default App;
