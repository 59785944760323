import "./bstyleCheckbox.css"


function bstyleCheckboxMain(props){

    return(
        <>   
            
            <div className="bstyleCheckboxConatiner">
                <input className="bstyleInputCheckbox" type="checkbox" checked={props.checked} onChange={props.onChange}/>
                <p className="bstyleCheckboxLabel" style={props.labelStyle}> {props.label} </p>
            </div>

            <div className="bstyleCheckboxAlertMessage">
                {props.alertMessage}
            </div>
                
        </>
    );
}

export default bstyleCheckboxMain;