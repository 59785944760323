import "./RegisterPage.css"
import { useState,useEffect, useRef, useId } from "react"
import BSoftTextInput from  "../smallComponents/bstyleInput"
import { useNavigate } from 'react-router-dom';
import BSoftButton2 from  "../smallComponents/BSoftButton2"
import BSoftButton1 from  "../smallComponents/BSoftButton1"
import BstyleCheckbox from  "../smallComponents/bstyleCheckbox"
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function SignUpPageDefaultFunc(){
    const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
    const navigate = useNavigate();
    const [getUser,setUser] = useState(
        {
            userFirstName: "",
            userLastName: "",
            userPhoneNumber:"",
            userEmailAdress:"",
            userPassword:"",
            userConfirmPassword:""
        }
    );

    const [inputAlert,setInputAlert] = useState(
        {
            userFirstName: "",
            userLastName: "",
            userPhoneNumber:"",
            userEmailAdress:"",
            userPassword:"",
            userConfirmPassword:"",
            hasUserAcceptedPolicy:""
        }
    )
    const [isPolicyAccepted,setIsPolicyAccepted] = useState(false);

    const resetInputAlert = () => {
        setInputAlert({
            userFirstName: "",
            userLastName: "",
            userPhoneNumber:"",
            userEmailAdress:"",
            userPassword:"",
            userConfirmPassword:"",
            hasUserAcceptedPolicy:""
        });
    };

    const handleFormValueChange = (event) =>{
        setUser(previousState => {
            return {
              ...previousState, [event.target.id]: event.target.value    
              //ex;   inputItemName: "Headdphone" and  itemId: "previuosValue", inputItemPrice: "previuosValue", itemDiscount:"previuosValue" inputItemDiscount:"previuosValue"
            }
        });
        //alert(getUser.userFirstName);
        //alert(getUser.userLastName);
    };    

    const createNewAccount = ()=>{
        resetInputAlert();
        axios.post(`${baseUrl}/addUser`,
            {
                userFirstName: getUser.userFirstName,
                userLastName: getUser.userLastName,
                userPhoneNumber:getUser.userPhoneNumber,
                userEmailAdress:getUser.userEmailAdress,
                userPassword:getUser.userPassword,
                userConfirmPassword:getUser.userConfirmPassword,
                hasUserAcceptedPolicy:isPolicyAccepted
            }
        ).then(( response)=>{
            toast.success(response.data.errorMessage +"");   
            navigate(`/verify_email/${getUser.userEmailAdress}`)
        }
        ).catch(function (error) {
            if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='userFirstName' ){
                setInputAlert(prevState => ({ ...prevState, userFirstName: error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='userLastName'){
                setInputAlert(prevState => ({ ...prevState, userLastName:error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='userPhoneNumber'){
                setInputAlert(prevState => ({ ...prevState, userPhoneNumber: error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='userEmailAdress'){
                setInputAlert(prevState => ({ ...prevState, userEmailAdress: error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='userPassword'){
                setInputAlert(prevState => ({ ...prevState, userPassword: error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='userConfirmPassword'){
                setInputAlert(prevState => ({ ...prevState, userConfirmPassword: error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='hasUserAcceptedPolicy'){
                setInputAlert(prevState => ({ ...prevState, hasUserAcceptedPolicy: error.response.data.errorMessage+""}));   
            }else{
                toast.error(error.response.data.errorMessage+"");
            }   
        }
        );
    }

    const userFirstName = useId();

    
    useEffect(() => {
        
    },[isPolicyAccepted]);

    return(

        <>
            <div className='signUpPageContainer'>

                <div className="logoContainer">
                    <img src="/img/ic_bsoft_logo.png" alt=".." height="90"/>
                </div>
               
                <div className="signUpCardContainer">  

                    <div className="formTitle" >
                        Register
                    </div>                
                    
                    <form>

                        <div className="inputText inputTex"> 
                            <BSoftTextInput alertMessage={inputAlert.userFirstName} inputLabelStyle={{ color:'black' }} type="text" label="First Name"   id="userFirstName" onChange={handleFormValueChange}  />
                        </div>
              
                        <div className="inputText inputTex">
                            <BSoftTextInput  alertMessage={inputAlert.userLastName} inputLabelStyle={{ color:'black' }} type="text" label="Last Name" id="userLastName" onChange={handleFormValueChange} />
                        </div>
                        
                        <div className="inputText inputTex">      
                            <BSoftTextInput  alertMessage={inputAlert.userEmailAdress} inputLabelStyle={{ color:'black' }} type="text" label="Email adress" id="userEmailAdress" onChange={handleFormValueChange}/>
                        </div>

                        <div className="inputText inputTex">
                            <BSoftTextInput  alertMessage={inputAlert.userPassword} inputLabelStyle={{ color:'black' }} type="password" label="Password" id="userPassword" onChange={handleFormValueChange}/>
                        </div>

                        <div className="inputText inputTex">
                            <BSoftTextInput  alertMessage={inputAlert.userConfirmPassword} inputLabelStyle={{ color:'black' }} type="password" label="Confirm Password" id="userConfirmPassword" onChange={handleFormValueChange}/>
                        </div>
                        < div className="inputText inputTex checkboxInputContainer">
                          <BstyleCheckbox 
                                alertMessage={inputAlert.hasUserAcceptedPolicy}
                                inputLabelStyle={{ backgroundColor: 'white', color:'black' }}
                                checked={isPolicyAccepted}
                                onChange={()=>{if( isPolicyAccepted==true) {setIsPolicyAccepted(false); return;}; setIsPolicyAccepted(true)}}
                                label={"Are you agree that informations you provide above is authentic and that your orders will be deliver with reference to those"}
                                
                           ></BstyleCheckbox>
                        </div>

                        <div className="buttonSubmitAccount" id="buttonSubmitAccount" onClick={createNewAccount} >
                            <BSoftButton1 label="Register Now" style={{ backgroundColor: 'white', color:'green' }} />
                        </div>

                        <p>Already Registered ? <a className="bstyleGreenLink" href="/login"> Login here</a></p>

                    </form>
                
                </div>

                <ToastContainer
                              position="top-right"
                              autoClose={5000}
                              hideProgressBar={true}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                />






                    
            
        
            


            </div>
        </>


        

    );
}

export default SignUpPageDefaultFunc;