import "./EmailVericationPage.css"
import { useState,useEffect, useRef, useId } from "react"
import BSoftTextInput from  "../smallComponents/bstyleInput"
import { useNavigate, useParams } from 'react-router-dom';
import BSoftButton2 from  "../smallComponents/BSoftButton2"
import BSoftButton1 from  "../smallComponents/BSoftButton1"
import BstyleCheckbox from  "../smallComponents/bstyleCheckbox"
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from "@chakra-ui/react";



function EmailVericationPageDefaultFunc(){
    const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
    const navigate = useNavigate();
    const [renderCount, setRenderCount] = useState(0);
    const [verificationOTP,setVerificationOTP] = useState('');
    const { email_address } = useParams();


    const [inputAlert,setInputAlert] = useState(
        {
            emailVerificationOTP: "",
        }
    )

    const resetInputAlert = () => {
        setInputAlert({
            emailVerificationOTP: "",
        });
    };

    const handleFormValueChange = (event) =>{
         setVerificationOTP(event.target.value)
    };    

    const checkEmailVerificationCode = ()=>{
        resetInputAlert();
        axios.post(`${baseUrl}/check_email_verification_code`,
            {
                userEmailAdress:email_address,
                verificationOTP:verificationOTP,
            }
        ).then(( response)=>{
            toast.success(response.data.errorMessage +"");   
            navigate('/login')
        }
        ).catch(function (error) {
            if(error.response.data.bsoftServerStatus==='4091' && error.response.data.field==='emailVerificationOTP' ){
                setInputAlert(prevState => ({ ...prevState, emailVerificationOTP: error.response.data.errorMessage+""}));   
            }else{
                toast.error(error.response.data.errorMessage+"");
            }   
        }
        );
    }


    const requestEmailVerificationCode = ()=>{
        resetInputAlert();
        axios.post(`${baseUrl}/request_email_verification_code`,
            {
                userEmailAdress:email_address,
            }
        ).then(( response)=>{
            toast.success(response.data.errorMessage +"");   
        }
        ).catch(function (error) {
            if(error.response.data.erorCode=='EROR_ALREADY_VERIFIED_MAIL'){
                navigate('/login')
                toast.error(error.response.data.errorMessage+"");
            }else{
                toast.error(error.response.data.errorMessage+"");
            }
        }
        );
    }
    
    useEffect(() => {
       /* if(renderCount<1){
            setRenderCount(renderCount+1)
        }

        if(renderCount===1){
           requestEmailVerificationCode()
        }*/
        requestEmailVerificationCode()
    }, []);

    return(

        <>
            <div className='emailVericationPageContainer'>

                <div className="logoContainer">
                    <img src="/img/ic_bsoft_logo.png" alt=".." height="90"/>
                </div>

                <div className="emailVerificationContainer">
                    <div className="emailVerificationLabel"> 
                        Email Verification
                    </div>
                    <div className="inputText inputTex"> 
                        <BSoftTextInput alertMessage={inputAlert.emailVerificationOTP} inputLabelStyle={{ color:'black' }} type="text" label="Verification Code"   id="emailVerificationOTP" onChange={handleFormValueChange}  />
                    </div>

                    <div className="emailSentMessage"> 
                        We sent a verication code to <span className="emailAddressSpan"> {email_address} </span>, please check in your inbox, pickup the code and enter it here.
                    </div>


                    <div className="buttonSubmitVerification" id="buttonSubmitVerification" onClick={checkEmailVerificationCode} >
                        <BSoftButton1 label="Verify" style={{ backgroundColor: 'green', color:'white' }} />
                    </div>

                </div>
            
             

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />






                    
            
        
            


            </div>
        </>


        

    );
}

export default EmailVericationPageDefaultFunc;