import "./SectionServices.css"


function SectionServicesDefaultFunct(){
    return(

        <>

             <section class="sectionServices">

                <div className="serviceCardContainer">

                    <div className="serviceCardContainer0" >

                        <div className="serviceCardContainer1">
                            
                            <div className='serviceCard'>
                                <div class="iconService">
                                 <i class="fa-solid fa-tablet-screen-button"></i>
                                </div>

                                <h3> Mobile Developement</h3>

                                <p> We use the latest technology to build powerful apps for Android and IOS </p>   
                           
                            </div>

                            <div className='serviceCard'>
                                <div class="iconService">
                                 <i class="fa-solid fa-globe"></i>
                                </div>

                                <h3> Web Developement</h3>
                                
                                <p> We make modern websites and web apps with React and other last-generation technologies.        </p>
                            </div>

                            <div className='serviceCard'>
                                <div class="iconService">
                                 <i class="fa  fa-desktop"></i>
                                </div>

                                <h3> Desktop App Developement</h3>
                                <p> We make modern management software to help ou grown your bussiness.     </p>
                            
                            </div>
                                
                            <div className='serviceCard'>
                            
		                        <div class="iconService">
                                    <i class="fa-sharp fa-solid fa-robot"></i>
                                </div>

                                <h3>Iot & Robotics</h3>
                                
                                <p> We build electronics device to automate any kind of physical task. We use latest technology of Arduino and Rasbery-Pie to design complexe systems</p>

		                       
                            </div>
                    

                            <div className='serviceCard'>
                            	
                                <div class="iconService">
                                 <i class="fa-solid fa-brain"></i>
                                </div>

                                <h3> Artificial Intelligence </h3>
                                <p>  We go beyond the traditional way of programming and build systems that can learn to do some tasks by themselves. We can design machine learning models to help you achieve your goals. </p>

                            </div>    

                             <div className='serviceCard'>
                           	    <div class="iconService">
                                   <i class="fa-solid fa-shield"></i>
                                </div>

                                <h2> Cyber Security & Ethical Hacking</h2>
                                <p>  We help you find some vulnerabilties within your system and give you somes steps to secure it. </p>
                           
                            </div>


                             <div className='serviceCard'>
                           	    <div class="iconService">
                                   <i class="fa-solid fa-envelope"></i>
                                </div>

                                <h2> Domain Names & Professional Emails </h2>
                                <p>  Buy your domain name from us at a low price. We provide unlimited email addresses for your domain. </p>
                           
                            </div>

                             <div className='serviceCard'>
                           	    <div class="iconService">
                                   <i class="fa-solid fa-server"></i>
                                </div>

                                <h2> Hosting & Cloud </h2>
                                <p>  We deliver the best and most robust hosting infrastructure. We offer shared hosting plans as well as dedicated servers on the cloud. </p>
                           
                            </div>

                             <div className='serviceCard'>
                           	    <div class="iconService">
                                   <i class="fa-solid fa-network-wired"></i>
                                </div>

                                <h2> Network & Internet </h2>
                                <p>  We set up your office or home network and connect it to high-speed internet. </p>
                           
                            </div>                       
                        
                        </div>

                    </div>
        
                </div>   



            </section>
        
        
        </>


    );
}

export default SectionServicesDefaultFunct;