import "./bstyleTextarea.css"


function bstyleTextareaMain(props){

    return(
        <>   
            <div className="textareaConatiner">
                    
                <label className="textareaLabel" style={props.labelStyle} for="fname" >{props.label}</label>
                    
               
                <textarea className="bstyleTextarea" id={props.id} name={props.name} placeholder={props.placeholder} ref={props.ref} onChange={props.onChange} onKeyPress={props.onKeyPress} style={props.style} > </textarea>

                <div className="bstyleTextareaAlert" style={props.alertStyle}>
                    {props.alertMessage}
                </div>      
            
            </div>
                
        </>
    );
}

export default bstyleTextareaMain;