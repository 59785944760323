import React from 'react'
import './Navbar.css'
import NavbarSmall from '../Navbar/NavbarSmall'
import BSoftButton from  "../smallComponents/BSoftButton"
import BSoftButton1 from  "../smallComponents/BSoftButton1"
import BSoftTextInput from  "../smallComponents/bstyleInput"




function NavbarDefaultContent(){

    const [dimensions, setDimensions] = React.useState({ 
        viewportHeight: window.innerHeight,
        viewportWidth: window.innerWidth
    })
    React.useEffect(() => {

        function handleResize() {
            
            setDimensions({
                viewportHeight: window.innerHeight,
                viewportWidth: window.innerWidth
            })    
        }

       window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)    
        }
    }    

    )

    if(dimensions.viewportWidth<=836){
        return(<NavbarSmall/>);
    }else{
        return(
            <>
                 
    
                <div className='navbarContainer'>
                    
    
                        <div className='navbarLeftContainer'>
                            <div className="navbarBrandContainer" href="#">
                                <img src="/img/ic_bsoft_logo.png" alt=".." height="45"/>
                                Bonkoungou Soft
                            </div>
    
                            <div className='navvarMenuContainer'>
                                <ul className="menuItemsUnorderList">
                                    <li className="navMenuItem">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                    <li className='navMenuItem'>
                                    <a className="nav-link" href="#ourServices">Our services</a>
                                    </li>
                                    <li className="navMenuItem">
                                        <a className="nav-link" href="#aboutUs">About us</a>
                                    </li>
                                </ul>    
                            </div>
                        </div>
                       
                        
                        <div className='navbarRightContainer'>
                            <a className="nav-link" href="#contactUs">
                                <BSoftButton1 className="navbarButtonLoginin"  style={{maxHeight:50,maxWidth:320, height:'100%', width:'100%', 'background-color':'green', display:'flex', 'justify-content':'center', 'align-items':'center', color:'white'}} label="Contact us" />
                            </a>
                        </div>
    
    
    
                    
                </div>
               
    
            </>
    
               
    
    
    
        );
    }

    
}

export default NavbarDefaultContent;