import "./LoginPage.css"
import { useState,useEffect, useRef, useId, useContext } from "react"
import { useNavigate } from 'react-router-dom';
import BSoftTextInput from  "../smallComponents/bstyleInput"
import BSoftButton2 from  "../smallComponents/BSoftButton2"
import BSoftButton1 from  "../smallComponents/BSoftButton1"
import axios from "axios";
import { AppContext } from "../utils/AppContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





function LoginPageDefault(){
    const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
    const navigate = useNavigate();
    const {logout,login} = useContext(AppContext);
    const [getUser,setUser] = useState(

        {
            userEmailAdress:"",
            userPassword:"",
        }
    );
    const [setRequestError, requestError] = useState(null);

    const [inputAlert,setInputAlert] = useState(
        {
            userEmailAdress:"",
            userPassword:""
        }
    )

    const handleFormValueChange = (event) =>{
        setUser(previousState => {
            return {
              ...previousState, [event.target.id]: event.target.value    
              //ex;   inputItemName: "Headdphone" and  itemId: "previuosValue", inputItemPrice: "previuosValue", itemDiscount:"previuosValue" inputItemDiscount:"previuosValue"
            }
        });
        // alert("Mail: "+getUser.userEmailAdress)
        //alert("Password: "+getUser.userPassword)

    };

    const resetInputAlert = () => {
        setInputAlert({
          userEmailAdress: "",
          userPassword: ""
        });
    };

    const authUser = ()=>{
        resetInputAlert();  
        axios.post(
            `${baseUrl}/auth`,
            {
              userEmailAdress:getUser.userEmailAdress,
              userPassword:getUser.userPassword
            },
            { withCredentials: true }
        ).then((response)=>{
            login(response.data)
            navigate('/')
        }
        ).catch(function (error) {
            //toast.error(error.response.data.errorMessage+"");
            if(error.response.data.bsoftServerStatus=='4091' && error.response.data.field=='userEmailAdress' ){
                setInputAlert(prevState => ({ ...prevState, userEmailAdress: error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus=='4091' && error.response.data.field=='userPassword'){
                setInputAlert(prevState => ({ ...prevState, userPassword:error.response.data.errorMessage+""}));   
            }else if(error.response.data.bsoftServerStatus=='5090' && error.response.data.erorCode=='EROR_NotVerifiedMailAddress'){
                navigate(`/verify_email/${getUser.userEmailAdress}`)
                toast.error(error.response.data.errorMessage+"");
            }else{
                toast.error(error.response.data.errorMessage+"");
            }
        }
        );
    }

    useEffect(() => {

    }, [inputAlert]);


    

    
    return(

        <>
            <div className='loginPageContainer'>

                <div className="loginPageLogoContainer">
                    <img src="/img/ic_bsoft_logo.png" alt=".." height="90"/>
                </div>

               
                <div className="loginCardContainer">  

                    <div className="formTitle" >
                        Login
                    </div>                
                    
                    <form>

                       

                        <div className="inputText inputTex">      
                            <BSoftTextInput alertMessage={inputAlert.userEmailAdress} inputLabelStyle={{ backgroundColor: 'white', color:'black' }} type="text" label="Email adress" id="userEmailAdress" onChange={handleFormValueChange} onKeyPress={(event)=> { event.key === 'Enter' && authUser() } } />
                        </div>

                        <div className="inputText inputTex">
                            <BSoftTextInput alertMessage={inputAlert.userPassword} inputLabelStyle={{ backgroundColor: 'white', color:'black' }} type="password" label="Password" id="userPassword" onChange={handleFormValueChange} onKeyPress={(event)=> { event.key === 'Enter' && authUser() } } />
                            
                        </div>
                       

                        <div className="buttonSubmitLoginInfo" id="buttonSubmitLoginInfo" onClick={authUser}>
                            <BSoftButton1 label="Login" style={{ backgroundColor: 'white', color:'green' }} />
                        </div>

                        <a class="bstyleGreenLink" href="#">Forgot password {requestError} </a>

                        <p>Don't have an account? <a class="bstyleGreenLink" href="/register"> Create here</a></p>

                    </form>
                
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />



                    
            
        
            


            </div>
        </>


        

    );
}

export default LoginPageDefault;