import "./bstyleInput.css"

function BSoftEditTextDefaultFunct(props){

    return(
        <>   
            <div className="inputTextConatiner">
                
                <label className="inputLabel" style={props.inputLabelStyle}>{props.label}</label>
                
                <input className="bsoftTextInput" type={props.type} id={props.id} name={props.name} placeholder={props.placeholder} ref={props.ref} onChange={props.onChange} onKeyPress={props.onKeyPress} style={props.style} >
                </input>

                <div className="bstyleInputAlert" style={props.alertStyle}>
                    {props.alertMessage}
                </div>
                
            </div>

          
        </>
    )
}

export default BSoftEditTextDefaultFunct;