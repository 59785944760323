import "./FileUploadPage.css"
import {useState, useEffect, useRef}from 'react';
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function UploadPage(){
    const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;    
    const [selectedFile, setSelectedFile] = useState(null);

    const uploadFile = ()=>{
        alert("Uploading selected file....")
        const formData = new FormData();
        formData.append('selectedFile', selectedFile);
        Axios.post(
            `${baseUrl}/upload_file`,
            formData,
            {
                withCredentials: true,    
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                
            },

        ).then((response)=>{
            alert(response.data+ "");
            toast.success(response.data+ "");
        }
        ).catch(function (error) {
            toast.error(error.response.data+ "");
    
        });
    }


    const handleFormValueChange = (event) =>{      
        if (event.target.files != null) setSelectedFile(event.target.files[0]);        
    };    


    return(
        <>
          <div className='uploadFilePageContainer'>

            <div className="row">

                <div className="logoContainer">
                    <img src="/img/ic_bsoft_logo.png" alt=".." height="125"/>
                    <h1>Let's upload a file to bonkoungousoft.com</h1>
                </div>

                <form  className="uploaderForm">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">File to upload</label>
                          <input class="form-control" type="file" id="inputItemImageFile" name="itemImageFile" onChange={handleFormValueChange} />
                        </div>

                        <div className="mb-3">

                            <div className="d-grid gap-2">
                                <button type="button" className="buutonSendEmail btn btn-primary btn-lg" onClick={uploadFile}>
                                 Post Now
                                </button>
                            </div>

                        </div> 
                </form>

             

            </div>
            
          </div>
          
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover          
          />
          
        </>
    )
}

export default UploadPage;