import  './SectionsHead.css'

function SectionsHeadDefaultFunct(props){
    return(

        <>
           
            <div className='SectionHeadContainer'>

                <div className='SectionHeadTitlesContauner' id={props.id}>

                        <div className='SectionHeadSolid'>
                                
                        </div>

                        <div className='sectionTitleContainer'>
                                <div className='sectiontitle'>
                                     {props.sectiontitle}
                                </div>
                        </div>
                </div>
                
                 

            </div>
        
        </>
    );
}

export default SectionsHeadDefaultFunct;