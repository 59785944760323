import './SectionAboutUs.css'

function SectionAboutUsDefaultFunct(){
    return(
        <>
 

            <section class="sectionAboutUs">

                    <div class="sectionAboutUsContainer">

                        <div class="row">

                            <div class="col-md  img_col">
                                <img class="img-fluid imageCoverAboutUs" src="/img/img_about_us.jpg"  ></img>

                            </div>

                            <div class="col-md  sectionAboutUsTextContainer">
                                <p> 
                                    We are a tech startup skilled in software development, artificial intelligence, and robotics. We also offer web hosting; domain name registration and professional email services.
                                </p>

                            </div>
                        
                        </div>

        


                    </div>
                
           </section>

            

        </>
        
    );

   
}

export default SectionAboutUsDefaultFunct;