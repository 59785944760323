import axios from "axios";
import { createContext, useEffect, useState } from "react";
import Cookies from 'js-cookie';



export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
  const [currentUser, setCurrentUser] = useState( JSON.parse(localStorage.getItem("user")) || null );
  const [renderCount, setRenderCount] = useState(0)
  const [userPendingOrder, setUserPendingOrder] = useState(null);
  const [orderItems,setOrderItems] = useState(null);
  const [orderInvoice,setOrderInvoice] = useState(null);
  const [cartLenght, setCartLenght] = useState(0);
  const [shopItemsLenght, setShopItemsLenght] = useState(10);



   
  function verifyUserLoginState(){
    axios.post(`${baseUrl}/login_state`, 
      { 
                  
      },
      { withCredentials: true }
    
    ).then((response)=>{
    }
    ).catch(function (error) {
      if(error.response.data.bsoftServerStatus=='5090' && error.response.data.errorCode=='ERROR_NO_CREDENTIALS'){
        localStorage.removeItem('user');
        setCurrentUser(null);
      }else if(error.response.data.bsoftServerStatus=='5090' && error.response.data.errorCode=='ERROR_CREDENTIALS_CORRUPED'){
        localStorage.removeItem('user');
        setCurrentUser(null);
      }
    }
    );
  }

  const getUserPendingOrderFromServer=()=>{
    axios.post(
        `${baseUrl}/my_pending_order`,
        { 
            
        },
        { withCredentials: true }
    ).then((response)=>{
        setUserPendingOrder(response.data);
      }
    ).catch(function (error) {           
        if(error.response.data.bsoftServerStatus=='5090' && error.response.data.errorCode=='ERROR_NO_CREDENTIALS'){
          localStorage.removeItem('user');
          setCurrentUser(null);
        }else if(error.response.data.bsoftServerStatus=='5090' && error.response.data.errorCode=='ERROR_CREDENTIALS_CORRUPED'){
          localStorage.removeItem('user');
          setCurrentUser(null);
        }
          
      } 
    ); 

};

  const getOrderItems=(orderToGetId)=>{
      axios.post(
        `${baseUrl}/my_order_items`,
        { 
          orderId: orderToGetId,
        },
          { withCredentials: true }
        ).then((response)=>{
          setOrderItems(response.data);
          getOrderInvoice(orderToGetId)    
        }
        ).catch(function (error) {
          //toast.error(error.response.data+"");
          alert(error.response.data)
        }
  )}

  const getOrderInvoice=(myOrderId)=>{
    axios.post(
      `${baseUrl}/order_invoice`,
      { 
        orderId: myOrderId,
      },
        { withCredentials: true }
      ).then((response)=>{
        setOrderInvoice(response.data);    
      }
      ).catch(function (error) {
        //toast.error(error.response.data+"");
        alert(error.response.data)
      }
)}

  const login = async (userData) => {
    setCurrentUser(userData);
  };

  const logout = async () => {
    axios.post(`${baseUrl}/logout`, 
    { 
                
    },
    { withCredentials: true }
  
    ).then((response)=>{
      localStorage.removeItem('user');
      setCurrentUser(null);
      setRenderCount(renderCount+1)
    }
    ).catch(function (error) {
      
    }
    );

  };

  function refreshUserCart(){
    setRenderCount(renderCount+1)
  }

  useEffect(() => {
    getUserPendingOrderFromServer()
  },[currentUser, renderCount]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  },[currentUser]);

  useEffect(() => {
    if(userPendingOrder!=null && userPendingOrder.length!=0){
      getOrderItems(userPendingOrder.id);
    }
  },[userPendingOrder]); 

  useEffect(() => {
    if(orderItems!=null && orderItems.length!=0){
      setOrderItems(orderItems)
      setCartLenght(orderItems.length)
    }
  },[orderItems]); 

  return (
    <AppContext.Provider 
      value={{ 
        shopItemsLenght, setShopItemsLenght, refreshUserCart,   currentUser, login, logout,    cartLenght,setCartLenght,   userPendingOrder,setUserPendingOrder,   orderItems,setOrderItems,  orderInvoice, setOrderInvoice,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};