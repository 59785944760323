import "./BSoftButton1.css"

function ButtonDfaultFunct(props){
    return(
        <>
            <div className="bsoftButton1" style={props.style} onClick={props.onClick}>
                {props.label}
            </div>
        </>
    );
}

export default ButtonDfaultFunct;