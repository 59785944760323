import "./HomePage.css"
import Hero from "./Hero"
import SectionHead from '../SectionHead/SectionsHead'
import SectionAboutUs from "../SectionAboutUs/SectionAboutUs"
import SectionServices from "../SectionServices/SectionServices"
import SectionContactUs from "../SectionContactUs/SectionContactUs"




function HomePageDefaultFunct(){
     return(

        <div className="homePageContainer">
            
            <Hero/>

            <SectionHead sectiontitle="About us" id="aboutUs"></SectionHead>
            <SectionAboutUs/>

            <SectionHead sectiontitle="Our services" id="ourServices"></SectionHead>
            <SectionServices/>

            <SectionHead sectiontitle="Contact us" id="contactUs"></SectionHead>
            <SectionContactUs/>

            

        </div>

    );
}

export default HomePageDefaultFunct;
