import "./Hero.css"
import BSoftButton from  "../smallComponents/BSoftButton"


function HeroDefaultFunct(){
    return(

        <>
                <div className="heroContainer">
                    <div className="titleAndButtonHeroContainer">

                            <div className="heroTitle">
                                Welcome,
                            </div>

                            <div className="heroSubtitle">
                                We are so happy to provide you with the best IT Solutions.
                            </div>

                            <div className="buttonHero" onClick="" >
                                 
                            </div>
                    </div>

                   
                        

                </div>
        
        </>
    );

}

export default HeroDefaultFunct;